import * as React from 'react';
import cookie from 'react-cookie';
import { graphql } from 'gatsby';
import Text from './Text';
import { Link, StaticQuery } from 'gatsby';
import * as s from './Cookie.module.scss';

interface Props {
  hidden?: boolean;
}

class Cookie extends React.Component<Props> {
  state = {
    cookiesAccepted: false,
  };

  private cookieRef = React.createRef<any>();

  componentDidMount() {
    const cookie = this.isCookieSet('cookieAccepted');

    if (cookie) {
      this.setState({
        cookiesAccepted: true,
      });
    }
  }

  setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  getCookie = (cname) => {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  isCookieSet = (cookieName) => {
    var cookie = this.getCookie(cookieName);
    if (cookie === '') {
      return false;
    } else {
      return true;
    }
  };

  closeCookie = () => {
    const cookie = this.cookieRef.current;
    this.setCookie('cookieAccepted', true, 365);
    cookie && cookie.setAttribute('style', `height: 0`);
  };

  render() {
    const { cookiesAccepted } = this.state;
    const { hidden } = this.props;
    if (hidden) {
      return null;
    } else {
      return (
        <StaticQuery
          query={QUERY}
          render={(data) => {
            const { cookieText, cookieLinkTitle, cookieLink } =
              data.wp.themeGeneralSettings.cookieSettings;

            return (
              <div
                className={`${s.cookie} ${s.transition} ${
                  cookiesAccepted ? s.hidden : ''
                }`}
                ref={this.cookieRef}
              >
                <div className={s.textWrapper}>
                  <Text captionSmall>
                    {cookieText + ' '}
                    <Link to={cookieLink.uri}>{cookieLinkTitle}</Link>
                  </Text>
                </div>
                <div className={s.closeButton} onClick={this.closeCookie}>
                  <Text caption>Close</Text>
                  <div className={s.icon}>
                    <div className={s.bar} />
                    <div className={s.bar} />
                  </div>
                </div>
              </div>
            );
          }}
        />
      );
    }
  }
}

export const QUERY = graphql`
  query {
    wp {
      themeGeneralSettings {
        cookieSettings {
          cookieLinkTitle
          cookieText
          cookieLink {
            ... on WpPage {
              uri
            }
          }
        }
      }
      generalSettings {
        url
      }
    }
  }
`;

export default Cookie;
