import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/shared/Section';
import Footer from '../components/Footer';
import Hero from '../components/shared/Hero';
import Text from '../components/shared/Text';
import * as s from './office.module.scss';
import pin from '../img/location_pin.svg';
import styles from './mapStyle.json';
import { Image, SEO } from '../types';
import {
  Vertical,
  Horizontal,
} from '../swace/gatsby-background-image/GatsbyBackgroundImage';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { DynamicLink } from '../components/shared/components';
import Cookie from '../components/shared/Cookie';

interface MapComponentProps {
  lat: number;
  lng: number;
  isMarkerShown: boolean;
  googleMapURL: string;
  loadingElement: any;
  containerElement: any;
  mapElement: any;
}

const MyMapComponent = withScriptjs(
  withGoogleMap((props: MapComponentProps) => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{
        lat: props.lat, // latitude for the center of the map
        lng: props.lng, // longitude for the center of the map
      }}
      defaultOptions={{
        disableDefaultUI: true, // disable default map UI
        draggable: true, // make map draggable
        keyboardShortcuts: false, // disable keyboard shortcuts
        scaleControl: true, // allow scale controle
        scrollwheel: true, // allow scroll wheel
        styles, // change default map styles
      }}
    >
      <Marker
        position={{ lat: props.lat, lng: props.lng }}
        icon={{ url: pin }}
      />
    </GoogleMap>
  ))
);

export interface Props {
  data: {
    office: {
      office: {
        backgroundImage: Image;
        imagePositioning: {
          vertical: Vertical;
          horizontal: Horizontal;
        };
        address: {
          name: string;
          address: string;
          postNumberAndCity: string;
          country: string;
          eMail: string;
          phoneNumber: string;
        };
        position: {
          longitude: string;
          latitude: string;
        };
        cityDescription: string;
        cityName: string;
      };
      seo: SEO;
    };
    wordpressSiteMetadata;
    allJob;
  };
  internal: {
    type: string;
  };
}

// If longitude and latitude is not defined the fallback-location is Stockholm.
const stockholm = {
  longitude: 18.0686,
  latitude: 59.3293,
};

export class Office extends React.Component<Props> {
  render() {
    const { office, wordpressSiteMetadata: siteMeta, allJob } = this.props.data;
    const {
      cityName,
      cityDescription,
      backgroundImage,
      address,
      position,
      imagePositioning,
    } = office.office;

    // Preliminary latitude and longiture, will probably be set in wordpress later.
    const lat = parseFloat(position.latitude) || stockholm.latitude;
    const lng = parseFloat(position.longitude) || stockholm.longitude;

    const allJobsAtLocation =
      allJob &&
      allJob.edges &&
      allJob.edges.filter(
        (e) => e.node.relationships.location.attributes.city === cityName
      );

    return (
      <>
        <Layout seo={office.seo} siteMeta={siteMeta}>
          <Hero
            backgroundImage={backgroundImage}
            imagePositioning={imagePositioning}
          />
          <Section
            slimBottom={allJobsAtLocation && allJobsAtLocation.length > 0}
          >
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className={s.cityNameWrapper}>
                  <Text h2>{`${cityName}\n`}</Text>
                </div>
                <Text ingress>{cityDescription}</Text>
                <div className={s.addressWrapper}>
                  <Text p bold span preLine>
                    {`${address.name}\n`}
                  </Text>
                  <Text p span preLine>
                    {`${address.address}, ${address.postNumberAndCity}, ${address.country}\n${address.phoneNumber}, ${address.eMail}`}
                  </Text>
                </div>
              </div>
            </div>
          </Section>
          {allJobsAtLocation && allJobsAtLocation.length > 0 && (
            <Section>
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <Text h3>Open positions - {cityName}</Text>
                  <div className={s.positionsWrapper}>
                    {allJobsAtLocation.map((e, index) => (
                      <DynamicLink
                        to={`/positions/${e.node.links.relativeUrl}`}
                        key={'all_jobs_' + index}
                      >
                        <Text teal p>
                          {e.node.attributes.title}
                        </Text>
                      </DynamicLink>
                    ))}
                  </div>
                </div>
              </div>
            </Section>
          )}
          <Section wide slim>
            <MyMapComponent
              lat={lat}
              lng={lng}
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDhUH1J7KGs-Y_7iR4L4X78sZiEWxrELL4"
              loadingElement={<div className="h-100" />}
              containerElement={<div className={s.mapContainer} />}
              mapElement={<div className="h-100" />}
            />
          </Section>
          <Footer />
        </Layout>
        <Cookie />
      </>
    );
  }
}

export const pageQuery = graphql`
  query OfficeById($id: String!) {
    office: wpOffice(id: { eq: $id }) {
      office {
        address {
          address
          country
          eMail
          name
          phoneNumber
          postNumberAndCity
        }
        position {
          longitude
          latitude
        }
        cityDescription
        cityName
        backgroundImage: image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        imagePositioning {
          vertical: verticalAdjustment
          horizontal: horizontalAdjustment
        }
      }
      seo {
        title
        metaDesc
        canonical
        opengraphImage {
          sourceUrl
        }
      }
    }
    ...WordpressSiteMetadataFragment
  }
`;

export default Office;
